.batch-update-user{
    .steps-list{
        margin: 0 auto;
        max-width: 500px;
    }
    .next-btn-wraper {
        justify-content: right;
        .next-btn{
            margin-top: 30px;
            padding: 10px 20px 10px 20px;
        }
    }
    .text-link{
        color:blue;
        cursor: pointer;
    }
}