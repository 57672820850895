.table-permission {
  width: 100%;
  border-collapse: collapse;
  border:  1px solid rgba(255, 255, 255, 0.15)  !important;
  th,
  td {
    border:  1px solid rgba(255, 255, 255, 0.15) !important;
    padding: 8px 16px;
    height: 40px;
    text-align: center;
    min-width: 175px;
    white-space: nowrap;
  }
  .ant-select-focus {
    border: 1px solid rgba(0, 0, 0, 0.15) !important;
  }
  .ant-select-selector {
    background-color: transparent !important;
    color: #FFF !important;
  }
  .ant-select-arrow {
    color: #FFF;
  }
  .ant-select-selection-item {
    color: #FFF !important;
  }
}

.ant-select-dropdown {
  background-color: #2f3640!important;
  color: #FFF !important;
}
.ant-select-item-option-content {
  color: #FFF
}
.ant-select-item-option-active {
  background-color: #636e72 !important;
}
.ant-select-item-option-selected {
  background-color: #b2bec3!important;
}