.admin-detail {
    .detail-content {
        .basic-info {
            .left-info {
                text-align: center;
            }

            .right-info {
                padding-left: 20px;
                padding-right: 20px;
                border-left: 1px solid gray;
            }

            padding-bottom: 20px;
        }

        .more-info {
            padding-top: 20px;
            border-top: 1px solid gray;

            .grid {
                padding-right: 10px;
                padding-left: 10px;
            }
        }

        .post-list {
            padding-top: 20px;
            border-top: 1px solid gray;
        }
    }

    .save-permission {
        width: auto;
    }
}

.content3 {
    display: flex;
    justify-content: center;
    align-items: center;
}

.form-group {
    margin-bottom: 20px;
    width: 1000px;
}

label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

.form-control {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

button:hover {
    background-color: #0056b3;
}