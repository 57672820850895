.spinner-wrapper{
    position: relative;
    .spinner {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: black;
        opacity: 0.5;
        top: 0;
        left: 0;
        .pi-spinner{
            margin: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}
